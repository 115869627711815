<template>
  <div>
    <cta-banner :title="$route.name"/>
    <div class="container px-5 py-8 mx-auto" v-if="comites.length > 0">
      <div v-for="comite in comites" :key="`comite${comite.id}`" class=" py-20 px-10 my-14 rounded-lg bg-gray-50">
        <div class="flex flex-wrap w-full mb-10 flex-col items-center text-center">
          <span class="inline-block h-1 w-16 rounded bg-theme-color my-4"></span>
          <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 tracking-tight">{{comite.nombre}}</h1>
          <p class="lg:w-4/5 w-full leading-relaxed">{{comite.descripcion}}</p>
        </div>
        <div class="flex flex-wrap -m-4">
          <div class="xl:w-1/3 md:w-1/2 p-4 w-full" v-for="evento in comite.comiteevento" :key="`comite${evento.nombre}`">
            <div class="border border-gray-100 p-6 rounded-lg bg-white">
              <div class="flex items-center mb-3">
                <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-theme-color-light text-theme-color flex-shrink-0">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
                <h2 class="text-gray-900 text-lg title-font font-medium tracking-tight">{{evento.nombre}}</h2>
              </div>
              <p class="tracking-tight">{{evento.descripcion}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CtaBanner from '../../components/common/CtaBanner.vue';
import API from "@/api";
import {mapState} from "vuex";

export default {
  name: "Comites",
  components: {
    CtaBanner
  },
  data() {
    return {
      comites: [],
    };
  },
  computed: {
    ...mapState(["idEventoActual"]),
  },
  async created() {
    const { data } = await API.getComites({ evento_fk: this.idEventoActual })
    this.comites = data.results
  },
};
</script>

<style scoped>
.comite-area::before {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: var(--text-color-dark);
  bottom: -10px;
  left: calc(50% - 15px);
  z-index: 10;
  transform: rotate(45deg);
}
</style>
